import React, { useState, useEffect } from 'react';
import { Nav, Container, Col, Row } from "react-bootstrap";
import Logo from '../../images/icons/logo.svg'
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby";
import GetLink from '../functions/GetLink';
import BurgerMenu from './BurgerMenu';
import SearchOverlay from '../SearchOverlay/SearchOverlay';
import $ from 'jquery';
/**
 * Assets
 */
import './styles/_index.scss'; 
import OlarkScript from '../OlarkScript';
import { Helmet } from 'react-helmet';

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

const Header = () => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Top_Nav
          Sorting
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL            
          }
        }
      }
    }
  `)
  
  const menu_data = data.glstrapi.allMenus;

  // Search Overlay
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);

  const handleSearchOverlayClose = () => setShowSearchOverlay(false);
  const handleSearchOverlayShow = () => setShowSearchOverlay(true);
  // Search Overlay

  //Set Scroll
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    $('.main-nav .menu-link a').click(function () {
      $(window).scrollTop(0);
    })
    // try {
    //   window.addEventListener("scroll", () => {
    //     if ($(window).width() < 960) {
    //       setScroll(window.scrollY > 20);
    //     } else {
    //       setScroll(window.scrollY > 20);
    //     }  
    //   });
    // } catch { }
    var scrolled = false;
    $(window).scroll(function() {
      if($(window).scrollTop() > 10 && scrolled == false){
        $('.header').addClass('scrolled');
        scrolled = true;
      }else if($(window).scrollTop() == 0) {
        $('.header').removeClass('scrolled');
        scrolled = false;        
      }
    })
  }, []);


  return (
   <header className={`header fixed`}>
    <OlarkScript />
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col lg={3} md={6} className="logo-section">
            <Link to="/" className="navbar-brand">
                <img src={Logo} alt="logo" className="brand-logo" />
            </Link>
          </Col>
          <Col lg={6} className="main-nav-wrap">
            <Nav className="mr-auto justify-content-center">
              <ul className="main-nav">
              {menu_data.map((menuitem, key) => {
                if(menuitem.Show_In_Top_Nav === true) {
                    return(                 
                        <li className="menu-link">
                            <GetLink link={menuitem}/>   
                        </li>     
                    )
                }
              })}
              </ul>
            </Nav>
          </Col>
          <Col lg={3} md={6} className="toggle-nav-holder justify-content-end">
            <a className='header-tel' href="tel:+44 (0)20 7723 9988">+44 (0)20 7723 9988</a>
            <div className='header-icons'>
                <i className="icon-search" onClick={handleSearchOverlayShow}></i>
                <Link to={myaccountURL} className="sb-myacc"><i className="icon-user"></i></Link>
                <BurgerMenu menu_data={menu_data}/>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <ModalNav active={activeModal} toggle={setActiveModal} /> */}

      {/* Search overlay modal */}
      <SearchOverlay showOverlay={showSearchOverlay} closeOverlay={handleSearchOverlayClose} />
      {/* Search overlay modal */}
    </header>
  );
}

export default Header;
