import React, {useEffect} from 'react';
import { Container, Col, Row, Accordion } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';
import StaticpoplularSearch from './static-poplular-search';
import $ from 'jquery'
const PopularSearch = (props) => {
    useEffect(() => {
        $('.popular-search-wrapper li a').click(function () {
            $(window).scrollTop(0);
        })
    });
    return (
        <section className="popular-search-wrapper">
            <Container className="">
                <Row>
                    <Col lg={12}>
                        <Accordion className="popular-search-accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Popular Searches</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="accordion-row">
                                       <StaticpoplularSearch popularsearch = {props.popularsearch}/>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PopularSearch