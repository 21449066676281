import React, {useEffect} from 'react';
import { Col, Row, Form, Modal } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';
import { navigate } from "@reach/router";
import $ from 'jquery';
import SearchInputBox from '../predictive-search/search-areas';

const SearchOverlay = (props) => {

    useEffect(() => {
        let url = "/"
        $(".submit-search-buy").click(function () {
            var searcValue = $(".search-overlay-form .searchVal").val();
            if(searcValue) {
                url = "in-"+searcValue + "/"
            } else {
                url = "in-london/"
            }
            $(window).scrollTop(0);
            navigate("/property/for-sale/"+url)
        });
        $(".submit-search-rent").click(function () {
            var searcValue = $(".search-overlay-form .searchVal").val();
            if(searcValue) {
                url = "in-"+searcValue + "/"
            } else {
                url = "in-london/"
            }
            $(window).scrollTop(0);
            navigate("/property/to-rent/"+url)
        });
    })

    return (
        <Modal show={props.showOverlay} onHide={props.closeOverlay} className="search-overlay-modal">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="search-overlay-wrapper">
                    <div className="title-text-sm text-center">Property Search</div>
                    <h6 className="search-overlay-title text-center">Find a property in London and surrounding areas.</h6>
                    <Form.Group className="search-overlay-form" controlId="">
                        <Form.Label>Location</Form.Label>
                        {/* <Form.Control type="text" placeholder="Street, area or postcode" className='overlay-input-search' /> */}
                        <SearchInputBox/>
                    </Form.Group>
                    <Row className="search-overlay-btn-wrapper">
                        <Col>
                            <a href="javascript:;" className="submit-search-buy btn btn-primary-outline w-100" onClick={()=>props.closeOverlay(true)}>Buy</a>
                        </Col>
                        <Col>
                            <a href="javascript:;" className="submit-search-rent btn btn-primary-outline w-100" onClick={()=>props.closeOverlay(true)}>Rent</a>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SearchOverlay;