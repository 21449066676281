import React from 'react';
import { Col } from "react-bootstrap";
import { Link } from 'gatsby';

function StaticpoplularSearch(props) {
    //console.log("aaaaaaaaaaaaaaaaaaaa", props)
    return (
        <>
            {props.popularsearch != "Rent_Links" &&
            <Col lg={3} md={6}>
                <ul>
                    <li>
                        <Link to="/property/for-sale/in-london/">Property for sale in London</Link>
                    </li>
                    <li>
                        <Link to="/property/for-sale/in-regents-park/">Property for sale in Regents Park</Link>
                    </li>
                    <li>
                        <Link to="/property/for-sale/in-marylebone/">Property for sale in Marylebone</Link>
                    </li>
                    <li>
                        <Link to="/property/for-sale/in-gloucester-place/">Property for sale in Gloucester Place</Link>
                    </li>
                    <li>
                        <Link to="/property/for-sale/in-primrose-hill/">Property for sale in Primrose Hill</Link>
                    </li>
                </ul>
            </Col>
            }
            {props.popularsearch == "Sales_Links" &&
            <Col lg={3} md={6}>
                <ul>
                    <li>
                        <Link to="/property/for-sale/in-london/type-house/">Houses for sale in London</Link>
                    </li>
                    <li>
                        <Link to="/property/for-sale/in-london/type-apartment/">Flats for sale in London</Link>
                    </li>
                    <li>
                        <Link to="/property/for-sale/in-london/type-maisonettes/">Maisonettes for sale in London</Link>
                    </li>
                    <li>
                        <Link to="/property/for-sale/in-london/1-and-more-bedrooms/">1 Bedroom property for sale in London</Link>
                    </li>
                    <li>
                        <Link to="/property/for-sale/in-london/1-and-more-bedrooms/">2 Bedroom property for sale in London</Link>
                    </li>
                </ul>
            </Col>
            }
            {props.popularsearch != "Sales_Links" &&
            <Col lg={3} md={6}>
            <ul>
                    <li>
                        <Link to="/property/to-rent/in-london/">Property to rent in London</Link>
                    </li>
                    <li>
                        <Link to="/property/to-rent/in-regents-park/">Property to rent in Regents Park</Link>
                    </li>
                    <li>
                        <Link to="/property/to-rent/in-marylebone/">Property to rent in Marylebone</Link>
                    </li>
                    <li>
                        <Link to="/property/to-rent/in-gloucester-place/">Property to rent in Gloucester Place</Link>
                    </li>
                    <li>
                        <Link to="/property/to-rent/in-primrose-hill/">Property to rent in Primrose Hill</Link>
                    </li>
                </ul>
            </Col>
            }
            {props.popularsearch === "Rent_Links" &&
            <Col lg={3} md={6}>
            <ul>

                    <li>
                        <Link to="/property/to-rent/in-london/type-house/">Houses to rent in London</Link>
                    </li>
                    <li>
                        <Link to="/property/to-rent/in-london/type-apartment/">Flats to rent in London</Link>
                    </li>
                    <li>
                        <Link to="/property/to-rent/in-london/type-maisonettes/">Maisonettes to rent in London</Link>
                    </li>
                    <li>
                        <Link to="/property/to-rent/in-london/1-and-more-bedrooms/">1 Bedroom property to rent in London</Link>
                    </li>
                    <li>
                        <Link to="/property/to-rent/in-london/1-and-more-bedrooms/">2 Bedroom property to rent in London</Link>
                    </li>
                </ul>
            </Col>
            }
            <Col lg={3} md={6}>
                <ul>
                    <li>
                        <Link to="/property-management/">Property management in London</Link>
                    </li>
                    <li>
                        <Link to="/property-management/block-management/">Block management in London</Link>
                    </li>
                    <li>
                        <a target={`_blank`} href="https://ggfx-sandfords3.s3.eu-west-2.amazonaws.com/i.dev/tenantsguide_9fbe7d81f0.pdf">Tenant's guide to renting in London</a>
                    </li>
                    <li>
                        <a target={`_blank`} href="https://ggfx-sandfords3.s3.eu-west-2.amazonaws.com/i.dev/Landlords_Guide_72b122492d.pdf">Landlord's guide to letting in London</a>
                    </li>
                    <li>
                        <Link to="/meet-the-team/">Meet the team</Link>
                    </li>
                    
                </ul>
            </Col>
            <Col lg={3} md={6}>
                <ul>
                    <li>
                        <Link to="/contact-estate-agents-in-london/">Estate agents in Central and North West London</Link>
                    </li>
                    <li>
                        <Link to="/property-valution/">Property valuation in Central and North West London</Link>
                    </li>
                    <li>
                        <Link to="/about/area-guides/">London area guides</Link>
                    </li>
                    <li>
                        <Link to="/about/customer-reviews/">Customer testimonials</Link>
                    </li>
                    <li>
                        <Link to="/about/latest-property-news/">Latest property news</Link>
                    </li>
                </ul>
            </Col>
        </>
    );
}

export default StaticpoplularSearch;
