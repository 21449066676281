import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Logo from '../../images/icons/logo.svg'
import { Link } from 'gatsby';
import SearchOverlay from '../SearchOverlay/SearchOverlay';

/**
 * Assets
 */
import './styles/_burger_menu.scss';
import Menudata from './MenuData';

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

function BurgerMenu() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    // Search Overlay
    const [showSearchOverlay, setShowSearchOverlay] = useState(false);
    const handleSearchOverlayClose = () => setShowSearchOverlay(false);
    const handleSearchOverlayShow = () => setShowSearchOverlay(true);
    // Search Overlay
    return (
        <React.Fragment>
            <div className='burger-nav'>
                <a href="javascript:;" onClick={handleShow}>
                    <i className="icon-burger" onClick={handleShow}></i>
                </a>
                <Offcanvas show={show} onHide={handleClose} placement={`end`}>
                    <div className='modal-mobile-header'>
                        <div className='modal-logo'>
                            <Link to="/" className="navbar-brand">
                                <img src={Logo} alt="logo" className="brand-logo" />
                            </Link>
                        </div>
                        <div className='header-icons'>
                            <i className="icon-search" onClick={handleSearchOverlayShow}></i>
                            <Link to={myaccountURL} className=""><i className="icon-user"></i></Link>
                        </div>
                    </div>
                    <Offcanvas.Header closeButton>

                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Menudata />
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
      {/* Search overlay modal */}
      <SearchOverlay showOverlay={showSearchOverlay} closeOverlay={handleSearchOverlayClose} />
      {/* Search overlay modal */}
        </React.Fragment>
    )
}

export default BurgerMenu